.catering-main-wrapper {
  display: flex;
  flex-direction: column;
}

.image-style {
  object-fit: cover;
  height: 10vh;
}
.catering-title-wrapper {
  padding: 5%;

  font-size: 30px;
  font-weight: 500;

  text-align: center;

  margin-top: 20px;
}

.catering-title{
  border-bottom: 2px solid black;
  width: fit-content;
  padding-bottom: 10px;
}

/* 

.catering-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.catering-content-wrapper > * {
  width: 100%;
} */

@media (min-width: 778px) {
  /* .catering-content-wrapper > * {
    width: 75%;
  } */

  .image-style {
    height: 15vh !important;
  }

  .catering-title-wrapper {
    padding: 1% 15%;

    font-size: 30px;
    font-weight: 500;

    text-align: left;
  }
}
