.single-brand-wrapper {
  width: 100%;
  height: fit-content;
  margin: 10px 0;
}

.single-brand-img {
  width: 100%;

  border-radius: 60px;
  max-height: 250px;
}

.brand-information-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.brand-btn {
  margin-left: 12px;
}

.single-brand-text {
  font-size: 18px;
  font-weight: 600;

  margin: 15px 0
}
/* 
.brand-app-icons-styles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: fit-content;
} */

.brand-app-icon-style {
  width: 50px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.brands-btn-order-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 7px;
  

  width: 90%;
}

.brands-order-btn {
  padding-left: 10px;
  margin: 10px 20px 10px 0px;
}

.brands-order-btn:nth-child(1) {
  padding-left: 10px;
  margin: 10px 24px 10px 0px;
}

@media (min-width: 500px) and (max-width: 777px) {
  .single-brand-img {
    width: 100%;

    border-radius: 60px;
    max-height: 300px;
  }
}

@media (min-width: 778px) {
  .single-brand-wrapper {
    width: 40%;
  }

  .single-brand-img {
    width: 100%;

    border-radius: 60px;
    height: 55%;
  }
}

@media (min-width: 778px) and (max-width: 1199px) {
  .single-brand-img {
    max-height: 210px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .single-brand-img {
    max-height: 300px;
  }
}

@media (min-width: 1500px) {
  .single-brand-img {
    max-height: 350px;
  }
}
